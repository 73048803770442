

import {defineComponent, ref, watch, computed, onMounted,reactive} from "vue";
import {l} from '@/core/helpers/lang';
import store from "@/store";
import {Cart} from "@/store/modules/AuthModule";
import NumberFormat from "@/core/functions/NumberFormat";
import _ from "lodash";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import {VueTelInput} from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import {api_url} from "@/ayar";
import post from "@/core/functions/post";

/*interface Step1 {
  appName: string;
  category: string;
}

interface Step2 {
  framework: string;
}

interface Step3 {
  dbName: string;
  dbType: string;
}

interface Step4 {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}*/

export default defineComponent({
  name: "Checkout",
  data() {
    return {
      bindProps: {
        placeholder: l('web:checkount:lang153'),
        required: false,
        autocomplete: 'off',
        name: 'phone',
        autoFormat: false,
        customValidate: false,
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false,
          styleClasses: 'shadow-none'

        }
      },
    };
  },
  components: {
    Field,
    ErrorMessage,
    VueTelInput
  },
  setup(){
    /*const _stepperObj = ref<StepperComponent | null>(null);
    const createAppRef = ref<HTMLElement | null>(null);
    const createAppModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<KTCreateApp>({
      appName: "",
      category: "1",
      framework: "1",
      dbName: "",
      dbType: "1",
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      cardExpiryMonth: "1",
      cardExpiryYear: "2",
      cardCvv: "123",
      saveCard: "1",
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
          createAppRef.value as HTMLElement
      );
    });

    const createAppSchema = [
      Yup.object({
        appName: Yup.string().required().label("App name"),
        category: Yup.string().required().label("Category"),
      }),
      Yup.object({
        framework: Yup.string().required().label("Framework"),
      }),
      Yup.object({
        dbName: Yup.string().required().label("Database name"),
        dbType: Yup.string().required().label("Database engine"),
      }),
      Yup.object({
        nameOnCard: Yup.string().required().label("Name"),
        cardNumber: Yup.string().required().label("Card Number"),
        cardExpiryMonth: Yup.string().required().label("Expiration Month"),
        cardExpiryYear: Yup.string().required().label("Expiration Year"),
        cardCvv: Yup.string().required().label("CVV"),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        hideModal(createAppModalRef.value);
      });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });*/

    const form = reactive({
      user:{
        full_name: '',
        phone: '',
        email: '',
        address: '',
        zip: '',
        country: '',
        city: '',
        district: '',
      },
      country: [],
      cities: [],
      district: [],
    });

    const onInput = (phone, phoneObject) => {
      if (phoneObject) {
        if (phoneObject.formatted) {
          form.user.phone = phoneObject.valid?phoneObject.country.dialCode + phoneObject.nationalNumber:'';
        }
      }
    }
    const changeCity = (event) =>{
      console.log(event)
    }

    const user = ref<any>({});

    const getData = async () => {
      let response = await post(api_url + 'checkout');
      user.value = await response.data.user;
      form.country = await response.data.country;
      form.cities = await response.data.cities;

      form.user = JSON.parse(JSON.stringify(user.value))

    }

    onMounted(getData)



    const parameters = computed(() => store.getters.getParameterList);
    const carts = ref({
      items: [],
      totalPriceWithoutTax: 0,
      totalTax: 0,
      totalDiscount: 0,
      totalHamPrice: 0,
      totalPrice: 0,
      totalQty: 0,
      userGeneralDiscount: 0
    });
    const items = ref([]);

    const computedCarts= computed(() => store.getters.currentUser.carts);

    watch(computedCarts, (newValue, oldValue) => {
      carts.value = newValue;
      items.value = newValue.items;
    })
    onMounted(()=>{
      carts.value=computedCarts.value ?? carts.value;
      items.value = computedCarts.value?.items ?? [];
    });





    return {
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      carts,
      items,
      l,
      NumberFormat,
      _,
      onInput,
      parameters,
      form,
      user,
      changeCity
      // handleStep,
      // formSubmit,
      // previousStep,
      // createAppRef,
      // currentStepIndex,
      // totalSteps,
      // createAppModalRef,
    }
  },
});
